<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.2334 6.18663C5.90682 4.70579 7.72345 3.64441 9.86023 3.64441C12.5711 3.64441 14.7667 5.35151 14.7667 7.45774C14.7667 9.2373 12.4047 10.7309 10.2851 11.1529C9.62022 11.2851 9.86023 12.6337 9.86023 13.3366M9.86023 16.3555H9.8725"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
// not hero
export default {
  name: "QuestionMark",
};
</script>
