export default {
  default: "flex",
  flex: {
    el: {
      root: "flex",
    },
  },
  basic: {
    el: {
      root: "flex justify-between items-center",
    },
  },
};
