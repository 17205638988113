<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
    <path
      d="M12.1199 7.62624c-1.7674 0-3.19998.95466-3.19998 2.13333 0 1.17863 1.43258 2.13333 3.19998 2.13333 1.7675 0 3.2.9547 3.2 2.1333 0 1.1787-1.4325 2.1334-3.2 2.1334m0-8.53336v8.53336m0-8.53336c1.184 0 2.2187.4288 2.7723 1.06666l-2.7723-1.06666zm0 0V6.55957m0 9.60003v1.0666m0-1.0666c-1.184 0-2.21864-.4288-2.77224-1.0667l2.77224 1.0667z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9999 2c2.5139 2.29667 5.8198 3.4841 9.1925 3.30173.2656 1.047.4075 2.14395.4075 3.27567 0 6.0734-4.0789 11.1759-9.6 12.6226-5.52106-1.4467-9.59999-6.5502-9.59999-12.6226a13.27936 13.27936 0 01.40746-3.27567C6.18006 5.4841 9.48605 4.29667 11.9999 2z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShieldDollar',
};
</script>
