import enUS from "./en-US.js"
import viVN from "./vi-VN"

export default {
	"en-US": {
		...enUS,
	},
	"vi-VN": {
		...viVN,
	},
}
